import React, { useEffect, useState } from 'react'
import Layout from '../../components/layouts/Layout'
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock'
import { Link } from 'react-router-dom'
import { Button, Flex, Input, PaginationProps, Table, TableColumnsType, DatePicker, notification } from 'antd'
import { Calendar2, DocumentDownload, Home2, SearchZoomIn1, Warning2 } from 'iconsax-react'
import './style.scss';
import { ArrowLeftOutlined, ArrowRightOutlined, LineOutlined, } from '@ant-design/icons'
import { mixPostV1 } from '../../services/mixin'
import * as XLSX from 'xlsx'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { RangePickerProps } from 'antd/es/date-picker'

const { RangePicker } = DatePicker

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  // Can not select days before today and today
  return current && current > dayjs().endOf('day')
}

const numberFormat = (number: any) => {
  let result
  if (typeof number === 'number') {
    result = new Intl.NumberFormat().format(number)
  } else {
    result = number
  }
  return result
}

const customPrevNext: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return (
      <Flex align='center' gap={8}>
        <ArrowLeftOutlined />
        <span>Previous</span>
      </Flex>
    )
  }
  if (type === 'next') {
    return (
      <Flex align='center' gap={8}>
        <span>Next</span>
        <ArrowRightOutlined />
      </Flex>
    )
  }
  return originalElement
}

interface DataType {
  key: React.Key;
  date: string;
  staff: string;
  bank: number;
  voucher: number;
  labor: number;
  match: number;
  foodsupply: number;
  money_proof: number;
  proof: number;
  final: number;
  profit: number;
  statusfinal: number;
  loss: number;
}

const columns: TableColumnsType<DataType> = [
  {
    title: 'Date',
    dataIndex: 'date',
  },
  {
    title: 'Staff Report',
    dataIndex: 'staff',
  },
  {
    title: 'Bank start (USD)',
    dataIndex: 'bank',
    align: 'right',
    render: (text) => <span>{ numberFormat(text) }</span>
  },
  // {
  //   title: 'Voucher (USD)',
  //   dataIndex: 'voucher',
  //   align: 'right',
  //   render: (text) => <span>{ numberFormat(text) }</span>
  // },
  {
    title: 'Bonus (USD)',
    dataIndex: 'bonus_amount',
    align: 'right',
    render: (text) => <span>{ numberFormat(text) }</span>
  },
  {
    title: 'Labor (USD)',
    dataIndex: 'labor',
    align: 'right',
    render: (text) => <span>{ numberFormat(text) }</span>
  },
  {
    title: 'Match (USD)',
    dataIndex: 'total_match',
    align: 'right',
    render: (text) => <span>{ numberFormat(text) }</span>
  },
  {
    title: 'Food + Supply (USD)',
    dataIndex: 'food_supply',
    align: 'right',
    render: (text) => <span>{ numberFormat(text) }</span>
  },
  {
    // title: 'Food + Supply (USD)',
    title: 'Money in proof (USD)',
    dataIndex: 'money_proof',
    align: 'right',
    render: (text) => <span>{ numberFormat(text) }</span>
  },
  {
    title: 'Final (USD)',
    dataIndex: 'final',
    align: 'right',
    render: (text, record, index) => <span>{ numberFormat(text) }</span>,
    // render: (text, record, index) => (
    //   <div className='ctrl-td-final'>
    //     { numberFormat(text) }
    //     {
    //       record.final !== record.money_proof && (
    //         <Warning2 size="20" color="#f9d361" variant="Bulk" />
    //       )
    //     }
    //   </div>
    // ),
  },
  {
    title: 'Loss (USD)',
    align: 'right',
    // render: (text, record) => <span>{ numberFormat(record.final - record.money_proof) }</span>
    render: (text, record) => <span>{ numberFormat((record.profit + record.final) - record.money_proof) }</span>
  },
  {
    title: 'Profit machine (USD)',
    dataIndex: 'profit',
    align: 'right',
    render: (text) => <span>{ numberFormat(text) }</span>
  },
  {
    title: 'Money of system (USD)',
    dataIndex: 'money_proof',
    align: 'right',
    render: (text, record, index) => (
      <div className='ctrl-td-final'>
        { numberFormat(record.profit + record.final) }
        {
          (record.profit + record.final) !== record.money_proof && (
            <Warning2 size="20" color="#f9d361" variant="Bulk" />
          )
        }
      </div>
    ),
  },
];

const ReportTotalIncome: React.FC = () => {
  // GET LIST
  const [items, setItems] = useState({
    list: [],
    total: 0,
  })
  const [tableLoading, setTableLoading] = useState(false)

  const [params, setParams] = useState<{
    limit: number,
    page_number: number,
    searchStaffName?: string,
    fromDate?: string,
    toDate?: string,
  }>({
    limit: 10,
    page_number: 1,
  })

  const getItems = async () => {
    setTableLoading(true)
    const res = await mixPostV1('/api/webAdmin/reportTotalIncome.php', params)

    if (res.status === 0) {
      const result:any = []
      res.data.map((item: any, index: number) => result.push({
        ...item,
        key: index,
        date: item.date_report,
        staff: item.staff_name,
        statusfinal: 0,
        bank: item.bank_start,
        labor: item.labor_amount,
        foodsupply: item.food_supply,
        proof: item.money_proof,
        final: item.final,
        profit: item.profit,
      }))
      setItems({
        ...items,
        list: result,
        total: res.totalRecord,
      })
      setTableLoading(false)
    }
  }

  useEffect(() => {
    getItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const onPageChange = (page: number) => {
    setParams({
      ...params,
      page_number: page,
    })
  }

  const onTableChange = (data: any) => {
    setParams({
      ...params,
      page_number: data.current,
      limit: data.pageSize,
    })
  }
  // END GET LIST

  // SEARCH
  const [searchTimeout, setSearchTimeout] = useState<any>(null)
  const onSearch = (query: string) => {
    setParams({
      ...params,
      page_number: 1,
      searchStaffName: query,
    })
  }
  const onEnter = (e: any)  => {
    onSearch(e.target.value)
  }
  const onChange = (e: any) => {
    clearTimeout(searchTimeout)
    const timeout = setTimeout(() => {
      onSearch(e.target.value)
    }, 250)
    setSearchTimeout(timeout)
  }

  // On Date Change
  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    if (dates) {
      setParams({
        ...params,
        page_number: 1,
        fromDate: dateStrings[0],
        toDate: dateStrings[1],
      })
    } else {
      setParams({
        ...params,
        page_number: 1,
        fromDate: '',
        toDate: '',
      })
    }
  };
  // END SEARCH

  // EXPORT EXCEL
  const getItemsByExcel = async () => {
    const customParams = {
      ...params,
      page_number: 1,
      limit: 10000,
    }

    try {
      const res = await mixPostV1('/api/webAdmin/reportTotalIncome.php', customParams)

      if (res.status === 0) {
        if (res.data.length > 0) {
          formattedDataExcel(res.data)
        } else {
          openNotification('error', 'There is no data to export!')
        }
      } else {
        openNotification('error', 'Oops, something went wrong!')
      }
    } catch (error) {
      openNotification('error', 'Oops, something went wrong!')
    }
  }
  const formattedDataExcel = (data: any) => {
    const formattedData = data.map((item: any) => {
      return {
        'Date': item.date_report,
        'Staff Report': item.staff_name,
        'Bank start (USD)': numberFormat(item.bank_start),
        'Bonus (USD)': item.bonus_amount,
        'Labor (USD)': numberFormat(item.labor_amount),
        'Match (USD)': numberFormat(item.total_match),
        'Food + Supply (USD)': numberFormat(item.food_supply),
        'Money in proof (USD)': numberFormat(item.money_proof),
        'Final (USD)': numberFormat(item.final),
        // 'Loss (USD)': numberFormat(item.final - item.money_proof),
        'Loss (USD)': numberFormat(item.loss),
        'Profit machine (USD)': numberFormat(item.profit),
        'Money of system (USD': numberFormat(item.profit + item.final),
      }
    })
    exportExcel(formattedData)
  }
  const exportExcel = (convertData: any): boolean => {
    try {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData)
      const workbook: XLSX.WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách')

      const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' })

      const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
        const data: Blob = new Blob([buffer], { type: 'application/octet-stream' })
        const link: HTMLAnchorElement = document.createElement('a')
        link.href = window.URL.createObjectURL(data)
        link.download = fileName
        link.click()
      }

      saveAsExcel(excelBuffer, `${"Total Income Report"}.xlsx`)
      return true // Export successful
    } catch (error) {
      console.error('Error exporting Excel:', error)
      return false // Export failed
    }
  }
  // END EXPORT EXCEL

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification()
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Request success!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    })
  }

  return (
    <>
      { contextHolder }

      <Layout contentClassName="report-total-income-content">
        <BreadcrumbBlock
          items={[
            { title: <Flex align='center' gap={16}><Home2 variant="Bulk" /><Link to="/">CMD Tech</Link></Flex>, },
            { title: 'Report', },
            { title: 'Total Income', },
          ]}
          title='Total Income'
          description=''
        />

        <div className="table-filter-block">
          <Input
            placeholder="Search by name"
            prefix={<SearchZoomIn1 size={20} variant="Bulk" />}
            className="rs-input t-input-ctrl table-filter-block__search"
            allowClear
            onPressEnter={(e) => onEnter(e)}
            onChange={(e) => onChange(e)}
          />

          <RangePicker
            allowClear
            className="rs-range-picker"
            suffixIcon={<Calendar2 size={20} />}
            separator={<LineOutlined />}
            placeholder={['From date', 'To date']}
            disabledDate={disabledDate}
            onChange={onRangeChange}
          />

          <Button
            icon={<DocumentDownload size={20} variant="Bulk" />}
            className="rs-button mis-auto t-h40"
            onClick={getItemsByExcel}
          >Export</Button>
        </div>

        <div className="t-table-block">
          <Table
            columns={columns}
            dataSource={items.list}
            className="rs-table table-text-nowrap match-height"
            showSorterTooltip={false}
            loading={tableLoading}
            scroll={{ x: true }}
            pagination={{
              current: params.page_number,
              pageSize: params.limit,
              position: ['bottomCenter'],
              showSizeChanger: true,
              showTitle: false,
              total: items.total,
              itemRender: customPrevNext,
              onChange: (e) => onPageChange(e),
            }}
            onChange={onTableChange}
          />
        </div>
      </Layout>
    </>
  )
}

export default ReportTotalIncome
